@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600&display=swap");

body {
  margin: 0;
  font-family: "Montserrat", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  --bg-color: #191a22;
  --swiper-theme-color: #f39200;
  --primary-color: #f39200;
  --content-width: 1150px;
}

.header-background {
  background-color: #191a22;
}

.button--round {
  background-color: white !important;
  color: #232023 !important;
  transition: all 0.2s !important;
  font-size: 1.25rem !important;
  height: 3.5rem !important;
  border-radius: 3rem !important;
  padding: 1rem 2.5rem !important;
  display: flex !important;
  gap: 1rem !important;
  align-items: center !important;
  font-weight: 700 !important;
}

@media (max-width: 768px) {
  .button--round {
    font-size: 1rem !important;
    height: 2.5rem !important;
    border-radius: 2rem !important;
    padding: 0.5rem 1.5rem !important;
  }
}

.button--round:hover {
  transform: scale(1.1) !important;
}
